import { GeniePracticePreference, GenieSite } from '@genie-engineering/genie-fhir-converter';
import {
  CareTeamMember,
  EMPTY_OBJECTS,
  isEmptyString,
  Lab,
  NULLISH,
  Pregnancy,
  Procedure,
  removeEmpty,
  SDK,
  SDKAddressBookOverride,
  SDKOverride,
} from '@genie-engineering/sdk-api';
import moment from 'moment';

import { GenieClient } from '../clients/genie';

import {
  convertGeniePatientToFhirPatient,
  convertGeniePracticePreferenceToFhirLocation,
  convertGeniePracticePreferenceToFhirOrganization,
  convertGeniePreferenceToFhirPractitioner,
  convertGenieProcedureToFhirProcedure,
  util,
} from '@genie-engineering/genie-interop-converter';
import { ConverterContext } from './type';

export const buildGetPatient = (
  genieClient: GenieClient,
  converterContext: ConverterContext
): SDK['getPatient'] => {
  const getPatient = async (id: string): Promise<fhir.Patient & SDKOverride> => {
    const geniePatient = await genieClient.getPatient(id);
    return convertGeniePatientToFhirPatient(converterContext, geniePatient);
  };
  return getPatient;
};

export const buildGetOrganization = (
  genieClient: GenieClient,
  converterContext: ConverterContext
): SDK['getOrganization'] => {
  const getOrganization = async (id: string): Promise<fhir.Organization & SDKOverride> => {
    const practicePreference = await genieClient.getPracticePreference(id);
    return convertGeniePracticePreferenceToFhirOrganization(converterContext, practicePreference);
  };
  return getOrganization;
};

export const buildGetLocation = (
  genieClient: GenieClient,
  converterContext: ConverterContext,
  allSites: GenieSite[]
): SDK['getLocation'] => {
  const getOrganization = async (id: string): Promise<fhir.Location & SDKOverride> => {
    const practicePreference = await genieClient.getPracticePreference(id);
    return convertGeniePracticePreferenceToFhirLocation(
      converterContext,
      practicePreference,
      allSites
    );
  };
  return getOrganization;
};

export const buildGetPractitioner = (
  genieClient: GenieClient,
  converterContext: ConverterContext,
  defaultPracticePreference: GeniePracticePreference,
  allSites: GenieSite[]
): SDK['getPractitioner'] => {
  const getPractitioner = async (id: string): Promise<fhir.Practitioner & SDKOverride> => {
    const preference = await genieClient.getPreference(id);
    return convertGeniePreferenceToFhirPractitioner(
      converterContext,
      preference,
      defaultPracticePreference,
      allSites
    );
  };
  return getPractitioner;
};

export const buildListPractitioners = (
  genieClient: GenieClient,
  converterContext: ConverterContext,
  defaultPracticePreference: GeniePracticePreference,
  allSites: GenieSite[]
): SDK['listPractitioners'] => {
  const listPractitioners = async (): Promise<(fhir.Practitioner & SDKOverride)[]> => {
    const preferences = await genieClient.listPreferences();
    return preferences.map((preference) => {
      return convertGeniePreferenceToFhirPractitioner(
        converterContext,
        preference,
        defaultPracticePreference,
        allSites
      );
    });
  };
  return listPractitioners;
};

export const buildCareTeam = (genieClient: GenieClient): SDK['getCareTeam'] => {
  return async (patientId: string): Promise<CareTeamMember[]> => {
    const careTeamMembers = await genieClient.getInterestedPartyByPatientId(patientId);
    return careTeamMembers.map((interestedParty) => {
      return {
        displayName: interestedParty.ProviderName || '',
        addressBookId: interestedParty.AB_Id_Fk || '',
      };
    });
  };
};

export const buildSearchPractitionerAddressBook = (
  genieClient: GenieClient
): SDK['searchPractitionerAddressBook'] => {
  return async (
    query: string,
    count: number,
    offset: number
  ): Promise<(fhir.Practitioner & SDKAddressBookOverride)[]> => {
    const addressBookRecords = await genieClient.searchPractitionerAddressBook(
      query,
      count,
      offset
    );
    return addressBookRecords.map((addressBookRecord) => {
      return {
        name: [
          {
            text: addressBookRecord.FullName || '',
          },
        ],
        addressBookId: addressBookRecord.Id,
      };
    });
  };
};

export const buildGetAddressBookData = (genieClient: GenieClient): SDK['getAddressBookData'] => {
  const removeEmptyValues = removeEmpty(EMPTY_OBJECTS, NULLISH, isEmptyString);
  const getAddressBookData = async (
    addressBookId: string | number
  ): Promise<(fhir.Practitioner & SDKAddressBookOverride) | undefined> => {
    const addressBookRecords = await genieClient.getAddressBookData(addressBookId);
    if (!addressBookRecords.length) return undefined;
    return removeEmptyValues({
      name: [
        {
          text: addressBookRecords[0].FullName || '',
        },
      ],
      addressBookId: addressBookRecords[0].Id,
      address: [
        {
          line: [
            addressBookRecords[0].Address1,
            addressBookRecords[0].Address2,
            addressBookRecords[0].Address3,
            addressBookRecords[0].Address4,
          ],
          city: addressBookRecords[0].Suburb,
          state: addressBookRecords[0].State,
          postalCode: addressBookRecords[0].Postcode,
        },
      ],
      medicareProviderNumber: addressBookRecords[0].ProviderNum || undefined,
    });
  };
  return getAddressBookData;
};

export const buildListAttachmentsByPatientId = (
  genieClient: GenieClient
): SDK['listAttachmentsByPatient'] => {
  const getAttachmentsByPatientId = async (id: string): Promise<Record<string, unknown>[]> => {
    const records = await genieClient.listAttachmentsByPatientId(id);
    return records.map((attachment) => {
      return {
        id: attachment.Id,
        description: attachment.Description,
        dateCreated: moment(attachment.GCS_CreatedAt).format('DD/MM/YYYY'),
        documentType: attachment.WinDocumentType || attachment.DocumentType,
      };
    });
  };
  return getAttachmentsByPatientId;
};

export const getAttachmentFileById = (genieClient: GenieClient): SDK['getAttachmentFile'] => {
  const impl = async (id: number | string): Promise<string> => {
    const filePath = await genieClient.getAttachmentFileById(id as number);
    return filePath;
  };
  return impl;
};

export const getLabList = (genieClient: GenieClient): SDK['getLabList'] => {
  return async (): Promise<Lab[]> => {
    const data = await Promise.all([
      genieClient.getPathologyLabList().then((pathlogyData) => {
        return pathlogyData
          .map((lab) => {
            return {
              id: lab.UUID || '',
              name: lab.Name || '',
              supportedRequestTypes: ['pathology'],
            };
          })
          .filter((lab) => {
            return lab.id && lab.name;
          });
      }),
      genieClient.getRadiologyLabList().then((radiologyData) => {
        return radiologyData
          .map((lab) => {
            return {
              id: lab.UUID || '',
              name: lab.XRayCompany || '',
              supportedRequestTypes: ['radiology'],
            };
          })
          .filter((lab) => {
            return lab.id && lab.name;
          });
      }),
    ]);
    return data.flat(1);
  };
};
export const getIsTrueFlag = (genieClient: GenieClient): SDK['isFeatureActive'] => {
  const impl = async (featureFlag: string): Promise<boolean> => {
    let mappedFeatureFlag = '';
    switch (featureFlag) {
      case 'flag_erequests_radiology':
        mappedFeatureFlag = 'ERadiology_Enabled';
        break;
      case 'flag_erequests_radiology_digital':
        mappedFeatureFlag = 'Erequests_Radiology_Digital';
        break
      default:
        return false;
    }
    return genieClient.isTrueFlag(mappedFeatureFlag);
  };
  return impl;
};

// TODO: Update function to return all procedure data
export const buildGetCurrentProcedure = (
  genieClient: GenieClient,
  converterContext: ConverterContext
): SDK['getCurrentProcedure'] => {
  return async (): Promise<Procedure> => {
    const currentProcedure = await genieClient.getCurrentProcedure();
    const billingItems = await genieClient.getBillingItems(
      currentProcedure.operations.map(({ ItemNum }) => ItemNum as string)
    );
    // pass in billing items for operations
    return convertGenieProcedureToFhirProcedure(
      converterContext,
      currentProcedure.procedure,
      currentProcedure.operations,
      currentProcedure.quoteSettings,
      billingItems
    );
  };
};

export const getCurrentPregnancyByPatientId = (
  genieClient: GenieClient,
  converterContext: ConverterContext
): SDK['getPatientCurrentPregnancy'] => {
  return async (patientId: string): Promise<Pregnancy | undefined> => {
    const preganancyRecords = await genieClient.getCurrentPreganancy(patientId);
    if (!preganancyRecords.length) return undefined;
    return util.convertToPregnancy(preganancyRecords[0], converterContext.timezone);
  };
};
